<!-- 综评成绩-综评成绩 -->
<template>
    <div class="synthesis-box">
        <div class="main-container">
            <div class="filter-wrap">
                <!-- 筛选 -->
                <expand-filter
                    :formData="formData"
                    :closeWidth="1190"
                    marginBottom="0"
                    @clickBtn="clickBtn"
                    @changeSel="changeSel"
                    @changeCascader="changeCascader"
                    @treeClick="filterTreeClick"
                    @changeBtnFormType="changeBtnFormType"
                ></expand-filter>
                <div class="button-line" v-hasPermi="['programmeScore:export']"></div>
                <div style="padding-bottom: 10px;margin-right: 10px;">
                    <el-button v-hasPermi="['programmeScore:export']" type="enquiry" @click="exportClick">导出</el-button>
                </div>
                <div class="filter-wrap-right">
                    <el-button icon="el-icon-refresh-left" @click="synchronizationClick">同步成绩</el-button>
                    <el-button v-has-permi="['programmeScore:roster']" @click="disqualificationCheck">不合格名单</el-button>
                    <el-tooltip class="item" effect="dark" content="统计的项目不合格的人名名单" placement="bottom-end">
                        <i class="el-icon-question" style="color: #666666;margin-left: 5px;"></i>
                    </el-tooltip>
                </div>
            </div>

            <div class="table-container">
                <!-- 统计数据 -->
                <div class="statistics">
                    <div class="statistics-title-column">
                        <div class="statistics-title">项目统计</div>
                        <div class="statistics-title">总分统计</div>
                    </div>
                    <div class="statistics-data">
                        <div v-for="item in statistics" :key="item.key" class="statistics-data-column">
                            <div class="statistics-data-column-row">
                                <span class="statistics-data-label">{{ item.project.label }}：</span>
                                <span class="statistics-data-value">{{ item.project.value }}</span>
                            </div>
                            <div class="statistics-data-column-row">
                                <span class="statistics-data-label">{{ item.totalScore.label }}：</span>
                                <span class="statistics-data-value">{{ item.totalScore.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 表格 -->
                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="tableConfig"
                    :tableData="tableData"
                    @linkClick="handlerTableLinkClick">
                    <template v-slot:examList="slotData">
                        <template v-for="(sitem, sindex) in slotData.data.examList">
                            <div v-if="slotData.item.examId === sitem.examId" :key="sindex" class="exams_sore">
                                <span class="exam_sore-info">{{ sitem.examScore }}</span>
                                <span class="exam_sore-line">|</span>
                                <span class="exam_sore-icon exam_sore-icon0" v-if="sitem.isPass === '1' && sitem.levelTypeName === '优秀'"></span>
                                <span class="exam_sore-icon exam_sore-icon0" v-else-if="sitem.isPass === '1' && sitem.levelTypeName !== '优秀'"></span>
                                <span class="exam_sore-icon exam_sore-icon2" v-else-if="sitem.isPass === '0'"></span>
                                <span class="exam_sore-text levelTypeName-cell" v-if="sitem.levelTypeName" >{{ sitem.levelTypeName }}</span>
                                <span class="exam_sore-text" v-else>-</span>
                            </div>
                        </template>
                        <div v-if="!slotData.data.examList.find(e => e.examId === slotData.item.examId)">-</div>
                    </template>
                    <template v-slot:total="slotData">
                        <div class="exams_sore exams_sore-total">
                            <span class="exam_sore-info">{{ slotData.data.total.examScore }}</span>
                            <span class="exam_sore-line">|</span>
                            <span class="exam_sore-icons exam_sore-icon0" v-if="slotData.data.total.isPass === '1' && slotData.data.total.levelTypeName === '优秀'"></span>
                            <span class="exam_sore-icons exam_sore-icon0" v-else-if="slotData.data.total.isPass === '1' && slotData.data.total.levelTypeName !== '优秀'"></span>
                            <span class="exam_sore-icons exam_sore-icon2" v-else-if="slotData.data.total.isPass === '0'"></span>
                            <span class="exam_sore-text levelTypeName-cell" v-if="slotData.data.total.levelTypeName" >{{ slotData.data.total.levelTypeName }}</span>
                            <span class="exam_sore-text" v-else>-</span>
                        </div>
                    </template>
                    <template v-slot:operation="slotData">
                        <el-button
                            v-has-permi="['programmeScore:edit']"
                            type="text"
                            @click="editOpenMeeting(slotData.data)"
                        >修改</el-button>
                    </template>
                </table-data>
                <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow" @pagination="getTableList(true)" />
            </div>
        </div>

        <!-- 导出弹窗 -->
        <dialog-wrapper :dialog-obj="exportObj" @handleClose="handleExportClose">
            <el-form class="dialog-export">
                <el-form-item>
                    <el-radio-group v-model="exportObj.exportInfo">
                        <el-radio label="导出综评成绩列表">导出综评成绩列表</el-radio>
                        <el-radio label="批量导出学生成绩单">批量导出学生成绩单</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right;">
                        <el-button @click="exportCancel">取消</el-button>
                        <el-button type="primary" @click="exportConfirm" :loading="exportLoading">确定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </dialog-wrapper>

        <!-- 不合格弹窗 -->
        <dialog-wrapper :dialog-obj="disqualificationObj" @handleClose="handleWClose">
            <synthesis-box-dialog @change="closeDialog" :disqualificationObj="disqualificationObj"></synthesis-box-dialog>
        </dialog-wrapper>

        <!-- 平均成绩详情弹窗 -->
        <dialog-wrapper :dialog-obj="resultsObj" @handleClose="handleDClose">
            <div style="text-align: right;">
                <el-button type="text" @click="exportStudentPDF(resultsObj)">导出PDF</el-button>
            </div>
            <table border="1px solid #eff1f8;" cellspacing="0"
                style="border:1px solid #eff1f8; border-collapse:collapse; width:100%; border-radius: 4px;">
                <tr>
                    <th colspan="2" >
                        <div style="color: #333; font-size: 18px; font-weight:bold; padding: 6px 0;">{{ resultsObj.studentTableTitle }}</div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div style="color: #505559; font-size: 14px; ; padding: 6px 0; text-align: center;">
                            <span>姓名：</span>
                            <span>{{ resultsObj.studentName }}</span>
                        </div>
                    </td>
                    <td>
                        <div style="color: #505559; font-size: 14px; ; padding: 6px 0; text-align: center;">
                            <span>唯一号：</span>
                            <span>{{ resultsObj.onlyCode }}</span>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="student-dialog-box">
                <el-table
                    ref="stable" id="stable"
                    style="width: 100%"
                    size="small"
                    border
                    :data="resultsObj.tableData"
                    :span-method="objectSpanMethod"
                    :header-cell-style="{ background:'#F1F1F1',color:'#2B2F33' }">
                    <el-table-column prop="examSubjectName" align="center" label="项ㅤ目" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="examTScore" align="center" label="成绩合计"></el-table-column>
                    <el-table-column align="center" label="成ㅤ绩">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: space-between;">
                                <div class="exasm-subject-name" :title="scope.row.examName">{{ scope.row.examName }}</div>
                                <div style="width: 45%;">{{ scope.row.examScore }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <div slot="empty">
                        <p :style="{'marginTop': '23px'}">暂无数据</p>
                    </div>
                </el-table>
            </div>

            <div style="text-align: right;">
                <el-button @click="resultsClose">关闭</el-button>
            </div>
        </dialog-wrapper>

        <!-- 编辑成绩弹窗 -->
        <dialog-wrapper v-if="editObj.dialogVisible" :dialog-obj="editObj" @handleClose="handleEditClose">
            <synthesis-box-edit :key="+new Date()" :editObj="editObj" @change="closeEditDialog"></synthesis-box-edit>
        </dialog-wrapper>
    </div>
</template>

<script>
import { debounce, DialogWrapper, Pagination } from "common-local";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";
import TableData from "@/components/scrollWrapper/Sub/TableData"
import SynthesisBoxDialog from './SynthesisBoxDialog.vue'; // 不合格弹窗
import SynthesisBoxEdit from './SynthesisBoxEdit.vue'; // 编辑成绩弹窗
import { downloadFile, formatTreeData } from '@/libs/utils';
import onResize from "@/mixins/onResize"

export default {
    name: 'SynthesisBox',
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        SynthesisBoxDialog,
        SynthesisBoxEdit
    },
    props: {},
    data() {
        return {
            hasFirstLoadPage: true,//当前页面是否是第一次被加载
            formData: {
                data: [{
                    type: 'select',
                    label: '',
                    value: '',
                    placeholder: '选择方案',
                    key: 'programmeId',
                    list: [],
                },{
                    type: 'select',
                    label: '',
                    value: '',
                    placeholder: '选择项目等级',
                    key: 'projScoreLevelId',
                    list: [],
                },{
                    type: 'select',
                    label: '',
                    value: '',
                    placeholder: '选择总分等级',
                    key: 'totalScoreLevelId',
                    list: [],
                },{
                    placeholder: '班级',
                    type: 'cascader',
                    key: 'organId',
                    value: '',
                    organType: '',
                    list: [],
                    cascaderProps: {
                        label: 'name',
                        multiple: false,
                        value: 'id',
                        checkStrictly: true,
                        emitPath: false
                    },
                    filter(data) {
                        return data.organType !== '1';
                    }
                },{
                    type: 'input',
                    label: '',
                    value: '',
                    placeholder: '姓名',
                    key: 'studentName',
                }, {
                    type: 'input',
                    label: '',
                    value: '',
                    placeholder: '唯一号',
                    key: 'onlyCode',
                }],
                btnList: [{
                    type: 'primary',
                    text: '查询',
                    fn: 'primary',
                    auth: ['programmeScore:list']
                }, {
                    type: 'enquiry',
                    text: '重置',
                    fn: 'reset',
                }],
                btnFormType: true
            },
            statistics: [
                {
                    key: 'total',
                    project: {
                        label: '总人次',
                        value: 'ㅤ'
                    },
                    totalScore: {
                        label: '总人数',
                        value: 'ㅤ'
                    }
                },
                {
                    key: 'notPass',
                    project: {
                        label: '不合格人次',
                        value: 'ㅤ'
                    },
                    totalScore: {
                        label: '不合格人数',
                        value: 'ㅤ'
                    }
                },
                {
                    key: 'pass',
                    project: {
                        label: '合格人次',
                        value: 'ㅤ'
                    },
                    totalScore: {
                        label: '合格人数',
                        value: 'ㅤ'
                    }
                },
                {
                    key: 'good',
                    project: {
                        label: '优秀人次',
                        value: 'ㅤ'
                    },
                    totalScore: {
                        label: '优秀人数',
                        value: 'ㅤ'
                    }
                },
                {
                    key: 'passRate',
                    project: {
                        label: '合格率(%)',
                        value: 'ㅤ'
                    },
                    totalScore: {
                        label: '合格率(%)',
                        value: 'ㅤ'
                    }
                },
                {
                    key: 'goodRate',
                    project: {
                        label: '优秀率(%)',
                        value: 'ㅤ'
                    },
                    totalScore: {
                        label: '优秀率(%)',
                        value: 'ㅤ'
                    }
                }
            ],
            graderId: '',
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: '',
                gradeId: '',
                classId: '',
                // 年级、班级主键传值
                organId: '',
                organType:'',
                // 项目成绩等级Id
                projScoreLevelId: '',
                // 总分成绩等级Id
                totalScoreLevelId: '',
                // 方案主键
                programmeId: '',
                studentName: '',
                // 学生唯一号
                onlyCode: '',
                jobScope: ['1','2','3']
            },
            loadingTable: false,
            examDataList: [],
            tableConfig: {
                thead: [],
                check: false,
                height: ''
            },
            thead: [
                {
                    label: "年级",
                    prop: "gradeName",
                    minWidth: "130px",
                    align: "center",
                    className: "text-spacing-reduction"
                },
                {
                    label: "班级",
                    prop: "className",
                    minWidth: "130px",
                    align: "center",
                    className: "text-spacing-reduction"
                },
                {
                    label: "姓名",
                    prop: "studentName",
                    type: "link",
                    minWidth: "130px",
                    align: "center",
                    className: "text-spacing-reduction"
                },
                {
                    label: "学号",
                    prop: "studyNo",
                    minWidth: "130px",
                    align: "center",
                    className: "text-spacing-reduction"
                },
                {
                    label: "唯一号",
                    prop: "onlyCode",
                    minWidth: "130px",
                    align: "center"
                },
                {
                    minWidth: '30px'
                },
                {
                    label: "ㅤ总分",
                    labelWidth: "160px",
                    type: "slot",
                    slotName: 'total',
                    align: "left",
                    className: "text-spacing-reduction"
                },
                {
                    label: "操作",
                    // labelWidth: "125px",
                    type: "slot",
                    slotName: 'operation',
                    className: "text-spacing-reduction"
                }
            ],
            headerList: [],
            tableData: [],
            total: 0,
            // 导出弹窗
            exportObj: {
                title: '选择导出内容',
                dialogVisible: false,
                width: '600px',
                exportInfo: ''
            },
            // 不合格名单弹窗
            disqualificationObj: {
                title: "不合格名单",
                dialogVisible: false,
                programmeId: '',
                keyId: '',
                width: "1200px"
            },
            // 平均成绩详情弹窗及相关
            mergeObj: {},
            mergeArr: ['programmeName','examSubjectName','examTScore','levelTypeName'],
            resultsObj: {
                title: '成绩详情',
                dialogVisible: false,
                width: '930px',
                studentTableTitle: '',
                onlyCode: '',
                realName: '',
                keyId: '',
                tableData: []
            },
            // 编辑弹窗
            editObj: {
                title: '编辑成绩',
                dialogVisible: false,
                width: '930px',
                studentTableTitle: '',
                studentName: '',
                programmeId: '',
                keyId: '',
                onlyCode: '',
                tableData: []
            },
            exportLoading: false
        }
    },
    filters: {},
    computed: {},
    watch: {},
    created() {
        this.init();
    },
    activated() {
        //做个判断 第一次进入这个页面不触发这个方法，否则这个方法在init()里边也会触发一次  如果没数据的话 会有两个相同的提示语
        if(!this.hasFirstLoadPage){
            this.handleSchemeList();
            this.handleProjectList();
            this.handleScoreList();
            this.resetFilterItem();
        }
        this.hasFirstLoadPage = false;
    },
    mounted() {
        setTimeout(() => {
            this.$refs.table.config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 127
        }, 100)
    },
    methods: {
        resetFilterItem(){
             this.formData.data.forEach((item) => {
                if(item.key !== 'programmeId') {
                    item.value = '';
                    this.listQuery[item.key] = '';
                }
            })
            this.listQuery.organId = '';
            this.listQuery.organType = '';
        },
        /**
         * @Description: 加载
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 08:52:19
         * handleTreeData 年级班级数据
         * handleProjectList 项目等级列表
         * handleScoreList 总分等级列表
         * handleGradeList 年级列表
         * handleSchemeList 方案列表
         */
        init() {
            this.handleTreeData();
            this.handleProjectList();
            this.handleScoreList();
            this.handleGradeList();
            this.handleSchemeList();
        },
        /**
         * @Description:年级班级获取数据
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-27 15:43:12
         */
        handleTreeData() {
            this._fet('/school/schoolOrgan/listAuthByCondition', {
                schoolId: this.$store.state.schoolId,
                jobScope: ['1','2','3']
            }).then((res) => {
                if (res.data.code === '200') {
                    let index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    this.formData.data[index].list = formatTreeData(res.data.data.list, 'id', 'parentOrg')
                    console.log( this.formData.data[index].list)
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        /**
         * @Description: 获取项目等级列表
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:12:30
         */
        handleProjectList() {
            // type 1项目 2总分
            this._fet('/school/schoolCerLevel/listByCondition', {
                schoolId: this.$store.state.schoolId,
                type: '1'
            }).then((res) => {
                if (res.data.code === '200') {
                    this.formData.data.forEach((fitem) => {
                        if (fitem.key === 'projScoreLevelId') {
                            fitem.list = [];
                            res.data.data.forEach((item) => {
                                let obj = {
                                    label: item.name,
                                    value: item.id
                                }
                                fitem.list.push(obj)
                            })
                        }
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取总分等级列表
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:12:36
         */
        handleScoreList() {
            // type 1项目 2总分
            this._fet('/school/schoolCerLevel/listByCondition', {
                schoolId: this.$store.state.schoolId,
                type: '2'
            }).then((res) => {
                if (res.data.code === '200') {
                    this.formData.data.forEach((fitem) => {
                        if (fitem.key === 'totalScoreLevelId') {
                            fitem.list = [];
                            res.data.data.forEach((sitem) => {
                                let obj = {
                                    label: sitem.name,
                                    value: sitem.id
                                }
                                fitem.list.push(obj)
                            })
                        }
                    })

                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description:获取年级信息列表
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:11:30
         */
        handleGradeList() {
            this._fet('/school/schoolOrgan/listByCondition', {
                schoolId: this.$store.state.schoolId,
                organType: '3'
            }).then((res) => {
                if (res.data.code === '200') {
                    res.data.data.list.forEach((item) => {
                        let obj = {
                            value: item.id,
                            label: item.name
                        }
                        this.formData.data.forEach((item) => {
                            if (item.key === 'gradeId') {
                                item.list.push(obj)
                            }
                        })
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description:获取班级信息列表
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:11:56
         */
        handleClazzList(gradeId) {
            let dataList = [];
            this.formData.data.forEach((item) => {
                if (item.key === 'classId') {
                    item.value = '';
                    item.label = '';
                }
            })
            this._fet('/school/schoolOrgan/getClassByGradeId', {
                schoolId: this.$store.state.schoolId,
                gradeId: gradeId
            }).then((res) => {
                if (res.data.code === '200') {
                    res.data.data.forEach((item) => {
                        let obj = {
                            value: item.id,
                            label: item.name
                        }
                        dataList.push(obj)
                        this.formData.data.forEach((item) => {
                            if (item.key === 'classId') {
                                item.list = dataList;
                            }
                        })
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description:获取方案信息列表
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:11:28
         */
        handleSchemeList() {
            this.loadingTable = true;
            this.formData.data.forEach((item) => {
                if (item.key === 'programmeId') {
                    item.list = [];
                }
            })
            this._fet('/school/schoolCerProgramme/listByCondition', {
                schoolId: this.$store.state.schoolId
            }).then((res) => {
                if (res.data.code === '200') {
                    if(res.data.data.length > 0) {
                        this.listQuery.programmeId = res.data.data[0].id;
                        this.graderId = res.data.data[0].id;
                    } else {
                        this.loadingTable = false;
                    }

                    this.formData.data.forEach((item) => {
                        if (item.key === 'programmeId') {
                            item.list = res.data.data.map(item =>({
                                value: item.id,
                                label: item.name
                            }));
                            if(res.data.data.length > 0) {
                                item.value = this.graderId;
                            }
                        }
                    })
                    this.handleDetailData();
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                    this.loadingTable = false;
                }
            });
        },
        /**
         * @Description:方案详情接口
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-27 14:00:55
         */
        handleDetailData() {
            let id = this.listQuery.programmeId ;
            if(id === '') {
                this.$message.warning('请先选择所要查询的方案');
                return false;
            }
            this._fet('/school/schoolCerProgramme/info/' + id, {}).then((res) => {
                if (res.data.code === '200') {
                    this.examDataList = res.data.data.examList;
                    console.log('[handleDetailData]this.examDataList:', this.examDataList)
                    // 分页列表
                    this.getTableList(1);
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                    this.loadingTable = false;
                }
            });
        },
        /**
         * @Description: 获取分页列表数据
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:18:36
         */
        getTableList(isPaging) {
            let obj = {};
            let dataList = [];
            let examDatasList = this.examDataList;
            this.headerList = [];
            if(this.listQuery.programmeId === '') {
                this.$message.warning('请先选择所要查询的方案');
                return false;
            }
            if (isPaging === 1) {
                this.listQuery.pageNum = 1;
            }

            this.listQuery.schoolId = this.$store.state.schoolId;
            this.handleAverageStatis();
            this._fet('/school/schoolCerScore/list', this.listQuery).then((res) => {
                if (res.data.code === '200') {
                    let reg = res.data.data.list;
                    /**
                     * @description: 增加判断  没有成绩数据时也把考试科目加进表头
                     * @author: 邵澳
                     * @param {*}
                     */
                    if(reg.length == 0){
                        if (examDatasList) {
                            for(let i = 0; i < examDatasList.length; i++) {
                                this.headerList.push({
                                    examId: examDatasList[i].examId,
                                    examName: examDatasList[i].examName
                                });
                            }
                        }
                    }else{
                         /**
                          * @description: 原有的逻辑
                          * @author: 邵澳
                          * @param {*} let
                          */
                         for(let j = 0; j < reg.length; j++) {
                            for(let k = 0; k < reg[j].examList.length; k++) {
                                for(let i = 0; i < examDatasList.length; i++) {
                                    if(reg[j].examList[k].examId === examDatasList[i].examId) {
                                        this.headerList.push({
                                            examId: examDatasList[i].examId,
                                            examName: examDatasList[i].examName
                                        });
                                    }
                                }
                            }
                            reg[j].ruleDataList = dataList;
                        }
                    }


                    // 数据去重
                    this.headerList = this.headerList.reduce(function(item, next) {
                        obj[next.examId] ? '' : obj[next.examId] = true && item.push(next);
                        return item;
                    }, []);
                    // 根据方案详情返回的顺序排序
                    this.headerList.sort((a, b) => {
                        let aIndex = this.examDataList.findIndex(e => e.examId === a.examId)
                        let bIndex = this.examDataList.findIndex(e => e.examId === b.examId)
                        return aIndex - bIndex;
                    })
                    console.log('[getTableList]this.headerList:', this.headerList, "this.examDataList:", this.examDataList);
                    let thead = this._.cloneDeep(this.thead)
                    let dynamicHeader = []
                    for (let e of this.headerList) {
                        dynamicHeader.push(
                            {
                                minWidth: '30px'
                            },
                            {
                                label: e.examName,
                                type: 'slot',
                                minWidth: '180px',
                                slotName: 'examList',
                                align: 'left',
                                examId: e.examId,
                                className: e.examName.length == 2 ? 'text-spacing-reduction' : ''
                            }
                        )
                    }
                    let pushIndex = thead.findIndex(e => e.label === '操作')
                    thead.splice(pushIndex, 0, ...dynamicHeader);
                    this.tableConfig.thead = thead
                    console.log('[getTableList]this.tableConfig.thead:', this.tableConfig.thead);

                    this.tableData = res.data.data.list;
                    console.log('[getTableList]this.tableData:', this.tableData);
                    this.total = res.data.data.totalCount;
                    this.$nextTick(() => {
                        this.$_resizeHandler()
                    })
                    setTimeout(()=>{
                        this.loadingTable = false;
                    }, 1000);
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            });
        },
        /**
         * @Description: 综评成绩-查询统计
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-25 18:17:16
         */
        handleAverageStatis() {
            this._fet('/school/schoolCerScore/queryStatis', {
                schoolId: this.$store.state.schoolId,
                programmeId: this.listQuery.programmeId,
                jobScope: ['1','2','3'],
                organId: this.listQuery.organId,
                organType: this.listQuery.organType,
                studentName:  this.listQuery.studentName,
                onlyCode:  this.listQuery.onlyCode
            }).then((res) => {
                if (res.data.code === '200') {
                    console.log("[handleAverageStatis]res.data.data:", res.data.data)
                    for (let item of this.statistics) {
                        item.project.value = res.data.data.examScore[item.key]
                        item.totalScore.value = res.data.data.programeScore[item.key]
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },

        /**
         * @Description: 操作按钮
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 08:59:30
         */
        clickBtn(ev) {
            this.loadingTable = true;
            switch (ev.item.fn) {
                case 'primary': // 查询
                    this.loadingTable = true;
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                        if (item.key == 'organId') {
                            this.listQuery['organType'] = item.organType;
                        }
                    })
                    setTimeout(() => {
                        this.handleDetailData();
                    }, 200)
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        if(item.key !== 'programmeId') {
                            item.value = '';
                            this.listQuery[item.key] = '';
                        }
                    })
                    this.listQuery.organId = '';
                    this.listQuery.organType = '';
                    this.handleDetailData();
                    break;
                default:
                    break
            }
        },
        /**
         * @Description: 搜索框change事件
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:06:17
         */
        changeSel(data) {
            if (data.key === 'gradeId') {
                this.handleClazzList(data.value)
            } else if (data.key === 'programmeId') {
                if(data.value) {
                    this.graderId = data.value;
                    this.listQuery.programmeId = data.value;
                    this.loadingTable = true;
                    this.handleDetailData();
                } else {
                    data.value = this.graderId;
                }
            }
        },
        /**
         * @Description: 年级班级改变事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-11 14:46:33
         */
        changeCascader(data){
            let node;
            if (data.ref.getCheckedNodes()[0]) {
                node = data.ref.getCheckedNodes()[0].data;
                data.data.organType = node.organType;
            } else {
                data.data.organType = '';
            }
            data.ref.dropDownVisible = false;
        },
        /**
         * @Description: 级联获取值
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 羡国柱
         * @Date: 2023-08-12 18:32:46
         */
        filterTreeClick(data) {
            this.listQuery.organId = data.data.id
            data.dt.value = data.data.name
            data.selRef.blur()
        },
        /**
         * @Description: 同步成绩
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 11:25:06
         */
        synchronizationClick: debounce(function () {
            if(this.listQuery.programmeId === '') {
                this.$message.warning('请先选择所要同步成绩的方案');
                return false;
            }
            this.loadingTable = true;
            this._fet('/school/schoolCerProgramme/updateLevel', {
                id: this.listQuery.programmeId
            }).then((res) => {
                if (res.data.code === '200') {
                    this.$message.success("同步成功");
                    this.getTableList(1);
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        }, 1000, true),

        /**
         * @Description: 导出
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 09:07:30
         */
        exportClick() {
            this.exportObj.exportInfo = '';
            this.exportObj.dialogVisible = true;
        },
        /**
         * @Description: 导出关闭
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 09:07:32
         */
        handleExportClose() {
            this.exportObj.dialogVisible = false;
        },
        /**
         * @Description: 导出取消
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 09:30:45
         */
        exportCancel() {
            this.exportObj.dialogVisible = false;
        },
        /**
         * @Description: 导出确定
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 09:30:45
         */
        exportConfirm() {
            if(this.exportObj.exportInfo === '') {
                this.$message.warning('请选择导出内容');
                return false;
            }
            if(this.exportObj.exportInfo === '导出综评成绩列表') {
                this.exportExcel();
            } else {
                this.exportZip();
            }
        },
        /**
         * @Description: 导出表格
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 15:19:38
         */
        exportExcel() {
            let obj = {}
            let filter = this.formData.data.filter(i => i.value !== '');

            filter.forEach(item => {
                obj[item.key] = item.value;
                if(item.key === 'organId') {
                    obj.organType = item.organType;
                    obj.organId = item.value;
                }
            })
            obj.schoolId = this.$store.state.schoolId;
            obj.jobScope = ['1','2','3'];

            if(this.listQuery.programmeId === '') {
                this.$message.warning('请先选择方案');
                return false;
            }
            console.log('[exportExcel]obj:', obj)
            this.exportLoading = true;
            downloadFile({url: '/school/schoolCerScore/exportList', form: obj}, (d) => {
                // console.log(d)
                this.$message.success('导出成功');
                this.exportLoading = false;
                this.exportObj.dialogVisible = false;
            }, (err) => {

            })
        },
        /**
         * @Description: 导出Zip(批量导出)
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 09:33:22
         */
        exportZip() {
            let obj = {}
            let filter = this.formData.data.filter(i => i.value !== '');
            filter.forEach(item => {
                obj[item.key] = item.value;
                if(item.key === 'organId') {
                    obj.organType = item.organType;
                    obj.organId = item.value;
                }
            })
            obj.schoolId = this.$store.state.schoolId;
            obj.jobScope = ['1','2','3'];

            if(this.listQuery.programmeId === '') {
                this.$message.warning('请先选择方案');
                return false;
            }
            this.exportLoading = true;
            downloadFile({url: '/school/schoolCerScore/exportZip', form: obj}, () => {
                this.$message.success('导出成功');
                this.exportObj.dialogVisible = false;
                this.exportLoading = false;
            }, () => {

            })
        },

        /**
         * @Description: 不合格人名名单查看
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 11:52:06
         */
        disqualificationCheck() {
            if(!this.listQuery.programmeId) {
                this.$message.warning('请先选择方案');
                return false;
            }
            this.disqualificationObj.keyId = Math.random() + '';
            this.disqualificationObj.programmeId = this.listQuery.programmeId;
            this.disqualificationObj.dialogVisible = true;
        },
        /**
         * @Description: 不合格弹窗关闭监听
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 14:04:51
         */
        closeDialog(data){
            this.disqualificationObj.dialogVisible = false;
        },
        handleWClose() {
            this.disqualificationObj.dialogVisible = false;
        },

        // 表格组件链接点击事件
        handlerTableLinkClick(ev, item) {
            // console.log("[handlerTableLinkClick]", ev, item)
            if (item.prop === "studentName") {
                this.detailsMeeting(ev)
            }
        },
        /**
         * @Description: 综评成绩详情
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 16:49:16
         */
        detailsMeeting(dataItem) {
            this.resultsObj.keyId = Math.random() + '';
            this.handleResultDetail(dataItem);
        },
        /**
         * @Description: 综评成绩详情
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-26 16:16:55
         */
        handleResultDetail(dataItem) {
            let dataList = [];
            this._fet('/school/schoolCerScore/studentScoreList', {
                schoolId: this.$store.state.schoolId,
                programmeId: this.listQuery.programmeId,
                onlyCode: dataItem.onlyCode,
                jobScope: ['1','2','3']
            }).then((res) => {
                if (res.data.code === '200') {
                    let reg = res.data.data;
                    this.resultsObj.studentTableTitle = '综合素质评价成绩单';
                    this.resultsObj.onlyCode = dataItem.onlyCode;
                    this.resultsObj.studentName = dataItem.studentName;
                    for(let j = 0; j < reg.length; j++) {
                        reg[j].courseList.map((item)=>{
                            dataList.push({
                                examSubjectName: reg[j].examName,
                                examTScore: reg[j].examScore,
                                examScore: item.examScore,
                                examId: item.examId,
                                examName: item.examSubjectName,
                                type: item.type
                            })
                        })
                    }
                    console.log("[handleResultDetail]dataList:", dataList)
                    this.getSpanArr(dataList, "examId")
                    this.resultsObj.tableData = dataList;

                    this.resultsObj.dialogVisible = true;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        getSpanArr(data, groupKey) {
            this.mergeArr.forEach((key, index1) => {
                let count = 0; // 用来记录需要合并行的起始位置
                this.mergeObj[key] = []; // 记录每一列的合并信息
                let compareKey = groupKey ? groupKey : key
                data.forEach((item, index) => {
                    // index == 0表示数据为第一行，直接 push 一个 1
                    if(index === 0) {
                        this.mergeObj[key].push(1);
                    } else {
                        // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
                        if(item[compareKey] === data[index - 1][compareKey]) {
                            this.mergeObj[key][count] += 1;
                            this.mergeObj[key].push(0);
                        } else {
                            // 如果当前行和上一行其值不相等
                            count = index; // 记录当前位置
                            this.mergeObj[key].push(1); // 重新push 一个 1
                        }
                    }
                })
            })
            console.log("[getSpanArr]this.mergeObj:", this.mergeObj)
        },
        /**
         * @Description: 平均成绩详情关闭
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 17:25:30
         */
        handleDClose() {
            this.resultsObj.dialogVisible = false;
        },
        resultsClose() {
            this.resultsObj.dialogVisible = false;
        },
        /**
         * @Description: 学生综评成绩详情导出PDF
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 17:25:30
         */
        exportStudentPDF(resultsObj) {
            let obj = {}
            let filter = this.formData.data.filter(i => i.value !== '');
            filter.forEach(item => {
                obj[item.key] = item.value;
            })
            obj.schoolId = this.$store.state.schoolId;
            obj.studentName = resultsObj.studentName;
            obj.onlyCode = resultsObj.onlyCode;
            downloadFile({url: '/school/schoolCerScore/exportPdf', form: obj}, () => {
                this.$message.success('导出成功')
            }, () => {

            })
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            // console.log("[objectSpanMethod]", column, rowIndex, this.resultsObj.tableData, this.resultsObj.tableData[rowIndex])
            // console.log(this.mergeObj[column.property][rowIndex])
            // 判断列的属性
            if(this.mergeArr.indexOf(column.property) !== -1) {
                // 判断其值是不是为0
                if(this.mergeObj[column.property][rowIndex]) {
                    return [this.mergeObj[column.property][rowIndex], 1]
                } else {
                    // 如果为0则为需要合并的行
                    return [0, 0];
                }
            }
        },

        /**
         * @Description: 编辑成绩
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:28:53
         */
        editOpenMeeting(dataItem) {
            // console.log('编辑成绩 ', dataItem)
            this.editObj.keyId = Math.random() + '';
            this.handleEditDetail(dataItem);
        },
        /**
         * @Description: 编辑成绩详情
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:28:50
         */
        handleEditDetail(dataItem) {
            let dataList = [];
            this._fet('/school/schoolCerScore/studentScoreList', {
                schoolId: this.$store.state.schoolId,
                programmeId: this.listQuery.programmeId,
                onlyCode: dataItem.onlyCode,
                jobScope: ['1','2','3']
            }).then((res) => {
                if (res.data.code === '200') {
                    console.log("[handleEditDetail]res.data.data:", res.data.data)
                    let reg = res.data.data;
                    this.editObj.studentTableTitle = '综合素质评价成绩单';
                    this.editObj.onlyCode = dataItem.onlyCode;
                    this.editObj.studentName = dataItem.studentName;
                    this.editObj.programmeId = this.listQuery.programmeId;
                    for(let j = 0; j < reg.length; j++) {
                        reg[j].courseList.map((item)=>{
                            dataList.push({
                                examName: reg[j].examName,
                                examTScore: reg[j].examScore,
                                examScore: item.examScore,
                                examId: item.examId,
                                examSubjectName: item.examSubjectName,
                                type: item.type,
                                id: item.id,
                                examSubjectId: item.examSubjectId
                            })
                        })
                    }
                    this.editObj.tableData = dataList;
                    console.log("[handleEditDetail]this.editObj.tableData:", this.editObj.tableData)
                    this.editObj.dialogVisible = true;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 编辑成绩关闭
         * @DoWhat:[无]
         * @UseScenarios:[无]
         * @Attention:[无]
         * @Author: 羡国柱
         * @Date: 2023-07-21 09:29:09
         */
        closeEditDialog(data) {
            if(data === 'YES') {
                this.loadingTable = true;
                this.handleDetailData();
            }
            this.editObj.dialogVisible = false;
        },
        handleEditClose(data) {
            this.editObj.dialogVisible = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.main-container{
    padding-right: 10px;
}
// 筛选
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 0px 4px 4px 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;

        ::v-deep .el-form .el-form-item:first-child .el-icon-circle-close {
            display: none;
        }
    }

    .filter-wrap-right {
        margin-left: 10px;
        white-space: nowrap;
        margin-left: auto;
    }
}

// 表格及统计
.table-container {
    background: #FFFFFF;
    border-radius: 4px;
    // margin: 0 12px 0px 0;

    // 统计
    .statistics {
        height: 102px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .statistics-title-column {
            display: flex;
            flex-direction: column;

            .statistics-title {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #343434;

                +.statistics-title {
                    margin-top: 15px;
                }
            }
        }

        .statistics-data {
            width: 75%;
            margin-left: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .statistics-data-column {
                display: flex;
                flex-direction: column;

                .statistics-data-column-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .statistics-data-label {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #505559;
                    }

                    .statistics-data-value {
                        font-size: 23px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #343434;
                    }
                }
            }
        }
    }

    .exams_sore {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        box-sizing: border-box;

        .exam_sore-info {
            width: 36px;
            text-align: left;
            color: #2b2f33;
            font-size: 14px;
            white-space: nowrap;
        }

        .exam_sore-line {
            color: #aaadaf;
            margin: 0 10px 0 6px;
        }

        .exam_sore-icons {
            width: 6px;
            height: 6px;
            margin-right: 7px;
            border-radius: 10px;
        }

        .exam_sore-icon {
            width: 6px;
            height: 6px;
            margin-right: 7px;
            border-radius: 10px;
        }
        .exam_sore-icon0 {
            background: #64cf6d;
        }
        .exam_sore-icon1 {
            background: #f8a828;
        }
        .exam_sore-icon2 {
            background: #ee3737;
        }

        .levelTypeName-cell {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

// 导出弹窗
.dialog-export {
    ::v-deep .el-radio-group {
        display: block;
    }

    ::v-deep .el-radio {
        display: block;
        height: 44px;
    }
}

// 详情弹窗
.student-dialog-box {
    min-height: 360px;
    margin: 16px auto;

    /* 鼠标悬浮行 */
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background-color: unset;
    }

    .exasm-subject-name {
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
